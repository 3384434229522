<template>
    <div class="container">
        <breadcrumb :breadcrumbList="breadcrumbListForm"></breadcrumb>
        <div class="main-content">
            <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
                :size="formSize"
            >
                <div class="title">基础信息</div>
                <el-form-item class="form-input" label="等级名称：" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入等级名称"></el-input>
                </el-form-item>
                <el-form-item class="form-input" label="需要成长值：" prop="points">
                    <el-input-number v-model="ruleForm.points" :controls="false" :min="0" placeholder="请输入需要成长值"></el-input-number>
                </el-form-item>
                <!-- <el-form-item class="form-input" label="保级成长值：" prop="keepLevelPoints">
                    <el-input-number v-model="ruleForm.keepLevelPoints" :controls="false" :min="0" style="margin-right: 10px;" placeholder="请输入保级成长值"></el-input-number>
                    <el-popover
                      placement="top-start"
                      :width="300"
                      trigger="hover"
                      content="若用户成长值高于保级成长值，则不会发生降级。若为空，则用户高于等级需要成长值才不会发生降级。"
                      >
                      <template #reference>
                          <el-icon style="font-size: 18px;"><QuestionFilled /></el-icon>
                      </template>
                    </el-popover>
                </el-form-item> -->
                <div style="display: flex">
                  <el-form-item label="卡面图片：" prop="cardFaceUrl">
                    <upload-img v-model="ruleForm.cardFaceUrl" :limit="1"></upload-img>
                    <div class="tips">支持jpg、png格式，最多上传1张</div>
                  </el-form-item>
                  <el-form-item label="该等级图标：" prop="levelIcon">
                      <upload-img v-model="ruleForm.levelIcon" :limit="1"></upload-img>
                      <div class="tips">支持jpg、png格式，最多上传1张</div>
                  </el-form-item>
                </div>
                <div class="title">权益内容</div>
                <div class="gift_list">
                  <div class="gift_title">会员权益：</div>
                  <div class="gift_box_list">
                    <div class="gift_box_item">
                      <el-checkbox v-model="ruleForm.enableBirthdayGiftCoupon" size="large" />
                      <img :src="ruleForm.birthdayGiftUrl" />
                      <div class="gift_box_text">
                        <p>生日礼券</p>
                        <span @click="openCoupon(1)">{{ruleForm.birthdayGiftCouponList&&ruleForm.birthdayGiftCouponList.length?'已选择('+ruleForm.birthdayGiftCouponList.length+')':'选择优惠券'}}</span>
                      </div>
                    </div>
                    <div class="gift_box_item">
                      <el-checkbox v-model="ruleForm.enableServeDiscount" size="large" />
                      <img :src="ruleForm.serveDiscountUrl" />
                      <div class="gift_box_text">
                        <p>服务折扣</p>
                        <span @click="openServe">{{ruleForm.serveDiscountList&&ruleForm.serveDiscountList.length?'已选择('+ruleForm.serveDiscountList.length+')':'选择服务'}}</span>
                      </div>
                    </div>
                    <div class="gift_box_item" v-for="(item,index) in fixedList" :key="index">
                      <el-checkbox v-model="item.enabled" size="large" />
                      <img :src="item.url" />
                      <div class="gift_box_text">
                        <p>{{item.name}}</p>
                        <span @click="openDialog(1,index,item)">编辑</span>
                        <span style="color: red;margin-left: 10px;" @click="remove(1,index)">删除</span>
                      </div>
                    </div>
                    <div class="gift_box_items" @click="openDialog(1)">
                      <el-icon><Plus /></el-icon>
                      <p>添加权益</p>
                    </div>
                  </div>
                </div>
                <div class="gift_list no_flex">
                  <div class="gift_title">升级礼包：升级成为该等级会员后立即发放</div>
                  <div class="gift_box_list">
                    <div class="gift_box_item">
                      <el-checkbox v-model="ruleForm.enableUpgradePacks" size="large" />
                      <img :src="ruleForm.upgradePacksUrl" />
                      <div class="gift_box_text">
                        <p>送优惠券</p>
                        <span @click="openCoupon(2)">{{ruleForm.upgradePackCouponList&&ruleForm.upgradePackCouponList.length?'已选择('+ruleForm.upgradePackCouponList.length+')':'选择优惠券'}}</span>
                      </div>
                    </div>
                    <div class="gift_box_item" v-for="(item,index) in upgradeList" :key="index">
                      <el-checkbox v-model="item.enabled" size="large" />
                      <img :src="item.url" />
                      <div class="gift_box_text">
                        <p>{{item.name}}</p>
                        <span @click="openDialog(2,index,item)">编辑</span>
                        <span style="color: red;margin-left: 10px;" @click="remove(2,index)">删除</span>
                      </div>
                    </div>
                    <div class="gift_box_items" @click="openDialog(2)">
                      <el-icon><Plus /></el-icon>
                      <p>添加权益</p>
                    </div>
                  </div>
                </div>
                <div class="gift_list no_flex">
                  <div class="gift_title">每月礼包：根据成为会员的日期，每月同一时间发放</div>
                  <div class="gift_box_list">
                    <div class="gift_box_item">
                      <el-checkbox v-model="ruleForm.enableMonthPacks" size="large" />
                      <img :src="ruleForm.monthPacksUrl" />
                      <div class="gift_box_text">
                        <p>送优惠券</p>
                        <span @click="openCoupon(3)">{{ruleForm.monthPackCouponList&&ruleForm.monthPackCouponList.length?'已选择('+ruleForm.monthPackCouponList.length+')':'选择优惠券'}}</span>
                      </div>
                    </div>
                    <div class="gift_box_item" v-for="(item,index) in monthlyList" :key="index">
                      <el-checkbox v-model="item.enabled" size="large" />
                      <img :src="item.url" />
                      <div class="gift_box_text">
                        <p>{{item.name}}</p>
                        <span @click="openDialog(3,index,item)">编辑</span>
                        <span style="color: red;margin-left: 10px;" @click="remove(3,index)">删除</span>
                      </div>
                    </div>
                    <div class="gift_box_items" @click="openDialog(3)">
                      <el-icon><Plus /></el-icon>
                      <p>添加权益</p>
                    </div>
                  </div>
                </div>
                <el-form-item class="form-input" label="权益说明：" prop="remark">
                    <el-input style="width: 480px" maxlength="800" show-word-limit v-model="ruleForm.remark" type="textarea" :autosize="{ minRows: 4}" placeholder="最多可输入800字" ></el-input>
                </el-form-item>
            </el-form>
            <div class="bottom">
                <el-button style="margin-right: 20px;" @click="cancel">取消</el-button>
                <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
            </div>
        </div>
        <FixedForm ref="FixedForm" @saveForm="saveFixedForm"></FixedForm>
        <SelecdCoupon ref="SelecdCoupon" @saveForm="saveCouponList"></SelecdCoupon>
        <SelecdServe ref="SelecdServe" @saveForm="saveServeList"></SelecdServe>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbListForm } from './config'
  import pageUpload from '@/components/page-upload/page-upload.vue'
  import UploadImg from '@/components/page-upload/UploadFileList.vue'
  import { useRoute } from 'vue-router'
  import router from '@/router'
  import { ElMessage } from 'element-plus'
  import FixedForm from './cpn/fixedForm.vue'
  import { popup } from '@/utils/pop-up'
  import { createPointsMember,getPointsMember,editPointsMember } from '@/service/main/member'
  import SelecdCoupon from './cpn/selecdCoupon.vue'
  import SelecdServe from './cpn/selecdServe.vue'
  
  export default defineComponent({
    props: {
      serveId: {
        type: String,
        default: ''
      },
    },
    components: {
      pageUpload,
      UploadImg,
      Breadcrumb,
      FixedForm,
      SelecdCoupon,
      SelecdServe
    },
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const editId = route.query.id
      const ruleForm = reactive({ 
        enableBirthdayGiftCoupon:false,//是否启用生日礼券
        enableMonthPacks:false,//是否启用月礼包
        enableServeDiscount:false,//是否启用服务折扣
        enableUpgradePacks:false,//是否启用升级礼券
        birthdayGiftUrl:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/d6cd3e97-4287-4a70-b6b6-3e8eca4ceecf.png',//生日礼券权益封面
        serveDiscountUrl:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/e7c8be1e-a939-4031-a0d4-9b176f3dec51.png',//服务折扣权益封面
        upgradePacksUrl:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/97887c8a-82c7-433f-a18b-545ae08fe100.png',//升级礼券权益封面
        monthPacksUrl:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/b3868842-ddf5-4587-86e4-d9ef94f695be.png',//月礼包券权益封面
        birthdayGiftCouponList:[],//生日礼券列表
        upgradePackCouponList:[],//升级礼券列表
        monthPackCouponList:[],//每月礼包列表
        serveDiscountList:[],//服务折扣列表
       })
      const FixedForm = ref()
      const SelecdCoupon = ref()
      const SelecdServe = ref()
      const rules = {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        points: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
      const ruleFormRef = ref()
      const fixedList = ref([])
      const upgradeList = ref([])
      const monthlyList = ref([])
  
      const rankList = computed(() => store.state.serve.rankList)
      const openDialog = (type,i,e) =>{
        if (e) {
          FixedForm.value.open({index:i,...e,type})
        } else {
          FixedForm.value.open({type})
        }
      }

      const remove = (type,i) =>{
        popup('确认删除该权益吗?', async() => {
          switch (type) {
            case 1:
              fixedList.value.splice(i,1)
              break;
            case 2:
              upgradeList.value.splice(i,1)
              break;
            case 3:
              monthlyList.value.splice(i,1)
              break;
          
            default:
              break;
          }
        })
      }

      const saveFixedForm = (data) =>{
        if (data.type==1) {
          if (data.index||data.index==0) {
            fixedList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url}
          }else{
            fixedList.value.push({enabled:false,name:data.name,url:data.url})
          }
        } else if (data.type==2) {
          if (data.index||data.index==0) {
            upgradeList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url,description:data.description}
          }else{
            upgradeList.value.push({enabled:false,name:data.name,url:data.url,description:data.description})
          }
        } else if (data.type==3){
          if (data.index||data.index==0) {
            monthlyList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url,description:data.description}
          }else{
            monthlyList.value.push({enabled:false,name:data.name,url:data.url,description:data.description})
          }
        }
        
      }
      const openCoupon = (e) =>{
        let dataList;
        if (e==1) {
          dataList=ruleForm.birthdayGiftCouponList
        }else if (e==2) {
          dataList=ruleForm.upgradePackCouponList
        }else if (e==3) {
          dataList=ruleForm.monthPackCouponList
        }
        SelecdCoupon.value.open({dataType:e,dataList})
      }

      const saveCouponList = (data) =>{
        if (data.dataType==1) {
          ruleForm.birthdayGiftCouponList = data.dataList
        }else if (data.dataType==2) {
          ruleForm.upgradePackCouponList = data.dataList
        }else if (data.dataType==3) {
          ruleForm.monthPackCouponList = data.dataList
        }
      }
      const openServe = () =>{
        SelecdServe.value.open(ruleForm.serveDiscountList)
      }
      const saveServeList = (data) =>{
        ruleForm.serveDiscountList = data
      }
  
      const initPage = async() => {
        if (editId) {
          let res = await getPointsMember(editId)
          for (const key in res.data) {
            if (Object.prototype.hasOwnProperty.call(res.data, key)) {
              ruleForm[key] = res.data[key];
            }
          }
          fixedList.value = res.data.memberCustomizeBenefitsList
          upgradeList.value = res.data.upgradeCustomizeBenefitsList
          monthlyList.value = res.data.monthCustomizeBenefitsList
        }
      }
      initPage()
  
      const cancel = () =>{
        router.back()
      }
      // 提取展示消息提示的公共函数
      const showMessage = (message, type) => {
          ElMessage({
              message,
              type
          });
      };

      // 处理编辑操作的函数
      const handleEdit = async (formData, editId) => {
          try {
              const res = await editPointsMember({...formData, id: editId });
              if (res.code === 0) {
                  showMessage('编辑成功','success');
                  router.back();
              } else {
                  // 这里可以根据不同的错误码做更细致的错误处理，暂时先统一提示错误消息
                  showMessage(res.msg, 'warning');
              }
          } catch (error) {
              showMessage('编辑操作出现异常，请稍后重试', 'error');
          }
      };

      // 处理新增操作的函数
      const handleCreate = async (formData) => {
          try {
              const res = await createPointsMember(formData);
              if (res.code === 0) {
                  showMessage('添加成功','success');
                  router.back();
              } else {
                  // 这里可以根据不同的错误码做更细致的错误处理，暂时先统一提示错误消息
                  showMessage(res.msg, 'warning');
              }
          } catch (error) {
              showMessage('新增操作出现异常，请稍后重试', 'error');
          }
      };
      const handleSaveClick = async (formEl = undefined) => {
          if (!formEl) return;
          // 进行表单验证
          const valid = await formEl.validate();
          if (valid) {
              const formData = {
                  // 使用更具表意性的变量名，清晰表示组合后的表单数据
                  combinedFormData: {
                    ...ruleForm,
                      memberCustomizeBenefitsList: fixedList.value,
                      upgradeCustomizeBenefitsList: upgradeList.value,
                      monthCustomizeBenefitsList: monthlyList.value
                  }
              };
              if (editId) {
                  await handleEdit(formData.combinedFormData, editId);
              } else {
                  await handleCreate(formData.combinedFormData);
              }
          } else {
              showMessage('请正确填写数据', 'warning');
          }
      };
  
      return {
        rankList,
        rules,
        ruleFormRef,
        ruleForm,
        handleSaveClick,
        breadcrumbListForm,
        cancel,
        fixedList,
        upgradeList,
        monthlyList,
        FixedForm,
        openDialog,
        saveFixedForm,
        remove,
        openCoupon,
        SelecdCoupon,
        saveCouponList,
        SelecdServe,
        openServe,
        saveServeList
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    min-width: 1080px;

    .main-content {
      padding: 20px;
      margin: 20px;
      background-color: #fff;

      .title {
        position: relative;
        padding-left: 12px;
        margin: 24px 0;
        margin-left: 20px;

        &::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0;
          width: 6px;
          height: 22px;
          background-color: rgba(42, 130, 228, 1);
        }
      }

      .gift_list {
        display: flex;
        margin-left: 32px;
        margin-bottom: 25px;

        .gift_title {
          font-size: 14px;
          padding-top: 10px;
          color: #B0B0B0;
          min-width: 70px;
        }

        .gift_box_list {
          display: flex;
          margin-left: 14px;
          flex-wrap: wrap;

          .gift_box_item {
            background-color: #FBF9FE;
            padding: 18px 15px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            margin-bottom: 10px;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin: 0 15px;
            }

            .gift_box_text {
              width: 100px;
              line-height: 26px;

              p {
                font-size: 15px;
              }

              span {
                font-size: 13px;
                color: #7948ea;
              }
            }
          }

          .gift_box_items {
            background-color: #FBF9FE;
            // padding: 10px 15px;
            height: 90px;
            width: 224px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            color: #7948ea;
            font-size: 25px;
            cursor: pointer;

            p {
              margin-top: 10px;
              font-size: 14px;
            }
          }
        }
      }

      .no_flex {
        display: block;

        .gift_title {
          margin-bottom: 15px;
          padding-top: 0px;
        }

        .gift_box_list {
          margin-left: 84px;
        }
      }
    }

    .online-reserve {
      &:deep(.el-input) {
        max-width: 120px;
        margin-left: 10px;
      }
    }

    &:deep(.el-input-number .el-input__inner) {
      text-align: left !important;
    }

    .tips {
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
    }

    .form-input {

      &:deep(.el-input, .el-select) {
        width: 300px;
      }

      &:deep(.el-select) {
        width: 300px;

        .el-input {
          width: 100%;
        }
      }

      &:deep(.el-input-number) {
        width: 300px;
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }
  }

  .container {
    &:deep(.el-table) {
      th.el-table__cell {
        background-color: rgba(239, 239, 239, 1) !important;

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

      .el-table__header-wrapper {

        .el-table__cell {

          background-color: rgba(239, 239, 239, 1);
          // padding: 9px 0 !important;
          // border:1px solid rgb(223, 223, 223);

          .cell {
            color: rgba(80, 80, 80, 1) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }

      }

      .cell {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
</style>
  